import { lazy, useMemo } from "react";
const InstanceRoute = lazy(
  () => import("./instance-route-DMUDS46N.js").then((module) => ({
    default: module.InstanceRoute
  }))
);
const useInstanceRoute = () => {
  return useMemo(
    () => ({
      "instance-route": {
        component: InstanceRoute
      }
    }),
    []
  );
};
export {
  useInstanceRoute
};
